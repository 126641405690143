var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "salary-rule-container" },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-col": { span: 3 },
            "wrapper-col": { span: 21 },
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticClass: "pay-mode",
              attrs: { label: "计薪天数", prop: "planPayMode" },
            },
            [
              [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "全月应计薪天数" } },
                  [
                    _c("DictionariesInput", {
                      staticClass: "times-input",
                      attrs: {
                        clearable: false,
                        parameter: "planpaymode",
                        placeholder: "请选择计薪时长方式",
                      },
                      model: {
                        value: _vm.ruleForm.planPayMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "planPayMode", $$v)
                        },
                        expression: "ruleForm.planPayMode",
                      },
                    }),
                    _vm.ruleForm.planPayMode === "3"
                      ? _c("CbNumber", {
                          staticClass: "small-input",
                          attrs: {
                            max: "31",
                            "decimal-separator": true,
                            "is-zero": false,
                            suffix: "天",
                          },
                          model: {
                            value: _vm.ruleForm.daysPerMonth,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "daysPerMonth", $$v)
                            },
                            expression: "ruleForm.daysPerMonth",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "bottom" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "tooltip-content" }, [
                            _c("span", [
                              _vm._v(
                                "日薪=固定工资÷" + _vm._s(_vm.planPayModeData)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("span", { staticClass: "tooltip-icon" }, [
                          _vm._v("?"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.planPayMode === "3"
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "月实际计薪天数" } },
                      [
                        _c("DictionariesInput", {
                          staticClass: "times-input",
                          attrs: {
                            clearable: false,
                            parameter: "actualpaymode",
                            placeholder: "请选择计薪时长方式",
                          },
                          model: {
                            value: _vm.ruleForm.actualPayMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "actualPayMode", $$v)
                            },
                            expression: "ruleForm.actualPayMode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              [
                _c(
                  "div",
                  { staticClass: "attendance-none-title flex-align-center" },
                  [
                    _c("a-icon", { attrs: { type: "info-circle" } }),
                    _vm._v("当计薪人员无考勤及排班表时，将按下方选项处理"),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "全月应计薪天数" } },
                  [
                    _c("DictionariesInput", {
                      staticClass: "times-input",
                      attrs: {
                        clearable: false,
                        parameter: "planpaymodenoattend",
                        placeholder: "请选择计薪时长方式",
                      },
                      model: {
                        value: _vm.ruleForm.planPayModeNoAttend,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "planPayModeNoAttend", $$v)
                        },
                        expression: "ruleForm.planPayModeNoAttend",
                      },
                    }),
                    _vm.ruleForm.planPayModeNoAttend === "4"
                      ? _c("CbNumber", {
                          staticClass: "small-input",
                          attrs: {
                            max: "31",
                            "decimal-separator": true,
                            "is-zero": false,
                            suffix: "天",
                          },
                          model: {
                            value: _vm.ruleForm.daysPerMonthNoAttend,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "daysPerMonthNoAttend",
                                $$v
                              )
                            },
                            expression: "ruleForm.daysPerMonthNoAttend",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "bottom" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "tooltip-content" }, [
                            _c("span", [
                              _vm._v(
                                "日薪=固定工资÷" + _vm._s(_vm.planNoAttend)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("span", { staticClass: "tooltip-icon" }, [
                          _vm._v("?"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.planPayModeNoAttend === "4"
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "月实际计薪天数" } },
                      [
                        _c("DictionariesInput", {
                          staticClass: "times-input",
                          attrs: {
                            clearable: false,
                            parameter: "actualpayModenoattend",
                            placeholder: "请选择计薪时长方式",
                          },
                          model: {
                            value: _vm.ruleForm.actualPayModeNoAttend,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "actualPayModeNoAttend",
                                $$v
                              )
                            },
                            expression: "ruleForm.actualPayModeNoAttend",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
            ],
            2
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "计算方式", prop: "salCalcMode" } },
            [
              _c("DictionariesInput", {
                staticClass: "line-input",
                attrs: {
                  clearable: false,
                  parameter: "salcalctype",
                  placeholder: "请选择计薪时长方式",
                },
                model: {
                  value: _vm.ruleForm.salCalcMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "salCalcMode", $$v)
                  },
                  expression: "ruleForm.salCalcMode",
                },
              }),
              _c("div", { staticClass: "tips" }, [
                _vm.ruleForm.salCalcMode === "1"
                  ? _c("span", [
                      _vm._v("新入职、离职员工正算,对全月在职员工反算"),
                    ])
                  : _vm.ruleForm.salCalcMode === "2"
                  ? _c("span", [_vm._v("全员采用正算")])
                  : _vm.ruleForm.salCalcMode === "3"
                  ? _c("span", [_vm._v("全员采用反算")])
                  : _vm._e(),
                _c("br"),
                ["1", "2"].includes(_vm.ruleForm.salCalcMode)
                  ? _c("span", [
                      _vm._v("正算法举例"),
                      _c("br"),
                      _vm._v("应发工资 = 日薪 * 实际出勤天数 "),
                      _c("br"),
                    ])
                  : _vm._e(),
                ["1", "3"].includes(_vm.ruleForm.salCalcMode)
                  ? _c("span", [
                      _vm._v("反算法举例"),
                      _c("br"),
                      _vm._v("应发工资 = 固定工资 - 日薪 * 缺勤天数 "),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "转正月", prop: "regSalCalcMode" } },
            [
              _c("DictionariesInput", {
                staticClass: "line-input",
                attrs: {
                  clearable: false,
                  parameter: "regsalcalctype",
                  placeholder: "请选择转正月计算方式",
                },
                model: {
                  value: _vm.ruleForm.regSalCalcMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "regSalCalcMode", $$v)
                  },
                  expression: "ruleForm.regSalCalcMode",
                },
              }),
              _vm.ruleForm.regSalCalcMode === "1"
                ? _c(
                    "a-tooltip",
                    { attrs: { placement: "bottom" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "tooltip-content" }, [
                          _c("span", [
                            _vm._v(
                              "固定工资 =（转正前固定工资*调薪前计薪天数+转正后固定工资*调薪后计薪天数）/本月计薪天数"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("span", { staticClass: "tooltip-icon" }, [
                        _vm._v("?"),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "调薪月", prop: "chgSalCalcMode" } },
            [
              _c("DictionariesInput", {
                staticClass: "line-input",
                attrs: {
                  clearable: false,
                  parameter: "chgsalcalctype",
                  placeholder: "请选择计薪时长方式",
                },
                model: {
                  value: _vm.ruleForm.chgSalCalcMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "chgSalCalcMode", $$v)
                  },
                  expression: "ruleForm.chgSalCalcMode",
                },
              }),
              _vm.ruleForm.chgSalCalcMode === "1"
                ? _c(
                    "a-tooltip",
                    { attrs: { placement: "bottom" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "tooltip-content" }, [
                          _c("span", [
                            _vm._v(
                              "固定工资 =（调薪前固定工资*调薪前计薪天数+调薪后固定工资*调薪后计薪天数）/本月计薪天数"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("span", { staticClass: "tooltip-icon" }, [
                        _vm._v("?"),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "入职计薪", prop: "daysToNextPeriod" } },
            [
              _vm._v(" 当月 "),
              _c("CbNumber", {
                staticClass: "small-input",
                attrs: { max: "27", suffix: "天" },
                model: {
                  value: _vm.ruleForm.daysToNextPeriod,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "daysToNextPeriod", $$v)
                  },
                  expression: "ruleForm.daysToNextPeriod",
                },
              }),
              _vm._v(" 至考勤周期最后一天入职，当月薪资合并至下月发放 "),
              _c(
                "div",
                { staticClass: "attendance-none-title flex-align-center" },
                [
                  _c("a-icon", { attrs: { type: "info-circle" } }),
                  _vm._v("入职计薪天数为0时，默认为入职当月发薪"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "计税规则", prop: "taxClacMode" } },
            [
              _c("DictionariesInput", {
                staticClass: "line-input",
                attrs: {
                  clearable: false,
                  parameter: "taxclacmode",
                  placeholder: "请选择计税规则",
                },
                model: {
                  value: _vm.ruleForm.taxClacMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "taxClacMode", $$v)
                  },
                  expression: "ruleForm.taxClacMode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }